import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import * as M from '../../app.models';
import * as E from '../../app.enums';
import { map, tap } from 'rxjs/operators';


const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'text/plain',
    'Content-Type': 'text/plain'
  }),
  'responseType': 'text'
};

@Injectable({
  providedIn: 'root'
})
export class NetService {
  constructor(private http: HttpClient) { }

  // setICanHelp(): Observable<void> {
  //   return of();
  // }

  getTeacherStudents() {
    return this.http.get<M.Student[]>(`${environment.gatewayUrl}/Students/ListStudent`);
  }

  getTeacherStudentDstChanges(studentId: number) {
    return this.http.get<M.DstChanges>(`${environment.gatewayUrl}/Students/GetTeacherStudentDstChanges?studentId=${studentId}`);
  }

  getStudentDetails(studentId: number) {
    return this.http.get<M.StudentDetails>(`${environment.gatewayUrl}/Students/GetStudentDetails?studentId=${studentId}`);
  }

  getListStudentFeedbacks(requestFilters: M.ListStudentFeedbacksFilters): Observable<M.StudentFeedback[]> {
    return this.http.post<M.StudentFeedback[]>(`${environment.gatewayUrl}/Students/ListStudentFeedbacks`, requestFilters);
  }

  getListLevelTests(studentId: number): Observable<M.LevelTest[]> {
    return this.http.get<M.LevelTest[]>(`${environment.gatewayUrl}/Students/ListLevelTests?studentId=${studentId}`);
  }

  getListAttendancesAndRecordings(studentId: number, fromDate: number, toDate: number) {
    return this.http.get<M.AttendanceAndRecordings[]>(`${environment.gatewayUrl}/Students/ListAttendances?studentId=${studentId}&fromDate=${fromDate}&toDate=${toDate}`);
  }

  getListStudentAttendanceSummaries(studentId: number) {
    return this.http.get<M.AttendanceSummary[]>(`${environment.gatewayUrl}/Students/ListStudentAttendanceSummaries?studentId=${studentId}`);
  }

  getListCoursesByStudent(studentId: number) {
    return this.http.get<M.StudentCourse[]>(`${environment.gatewayUrl}/Filters/StudentCourses?studentId=${studentId}`);
  }

  getListCourseStudents(courseId: number): Observable<M.Student[]> {
    return this.http.get<M.Student[]>(`${environment.gatewayUrl}/Students/ListCourseStudents?courseId=${courseId}`);
  }

  // getInfoForReorder(orderId: number) {
  //   return this.http.get<M.InfoForReorder>(`${environment.gatewayUrl}/Students/GetInfoForReorder?orderId=${orderId}`);
  // }

  listInfoForReorder(studentId: number, courseId?: number) {
    let endPoint = `${environment.gatewayUrl}/Students/ListInfoForReorder?studentId=${studentId}`;
    if (courseId) { endPoint += `&courseId=${courseId}`; }
    return this.http.get<M.InfoForReorder[]>(endPoint);
  }

  addUpdateInfoForReorder(infoForReorder: M.InfoForReorderViewRequest) {
    return this.http.post<string>(`${environment.gatewayUrl}/Students/AddUpdateInfoForReorder`, infoForReorder);
  }

  getStudentApplications(): Observable<M.StudentApplications> {
    return this.http.get<M.StudentApplications>(`${environment.gatewayUrl}/Students/GetStudentApplications`);
  }

  getAcademicStudentsForGrading(): Observable<M.AcademicStudentsForGrading> {
    return this.http.get<M.AcademicStudentsForGrading>(`${environment.gatewayUrl}/Students/GetAcademicStudentsForGrading`);
  }

  getQuestionaire(studentId: number): Observable<M.Questionaire> {
    return this.http.get<M.Questionaire>(`${environment.gatewayUrl}/Students/GetQuestionaire?studentId=${studentId}`);
  }

  listCompletedTypeFormQuestionnaires(studentId: number): Observable<M.QuestionaireResult[]> {
    return this.http.get<M.QuestionaireResult[]>(`${environment.gatewayUrl}/Students/ListCompletedTypeFormQuestionnaires?studentId=${studentId}`);
  }

  updatePedagogicalNotes(notes: string, orderId: number): Observable<string> {
    return this.http.post<string>(`${environment.gatewayUrl}/Students/UpdatePedagogicalNotes`, { notes, orderId });
  }

  updateAcademicStudentsForGrading(updateAcademicGrading: M.UpdateAcademicStudentsForGrading): Observable<string> {
    return this.http.post<string>(`${environment.gatewayUrl}/Students/UpdateAcademicStudentsForGrading`, updateAcademicGrading);
  }

  getTeacherCourseStudents(courseId: number): Observable<M.Student[]> {
    return this.http.get<M.Student[]>(`${environment.gatewayUrl}/Students/ListCourseStudents?courseId=${courseId}`);
  }

  getTeacher(id?: number): Observable<M.Teacher> {
    return this.http.get<M.Teacher>(`${environment.gatewayUrl}/teachers/Get`);
  }

  getTeacherProfile(id?: number): Observable<M.TeacherProfile> {
    return this.http.get<M.TeacherProfile>(`${environment.gatewayUrl}/Teachers/Profile/${id || 0}`);
  }

  getTeacherCourses(filterType: E.TeacherCoursesFilterType = E.TeacherCoursesFilterType.None, semesterId?: number, groupId?: number, forTeacherId?: number): Observable<M.TeacherCourse[]> {
    let endpoint = `${environment.gatewayUrl}/filters/TeacherCourses?filterType=${filterType}`;
    endpoint += semesterId ? `&semesterId=${semesterId}` : '';
    endpoint += groupId ? `&groupId=${groupId}` : '';
    endpoint += forTeacherId ? `&forTeacherId=${forTeacherId}` : '';
    return this.http.get<M.TeacherCourse[]>(endpoint);
  }

  getTeacherCampuses(id?: number): Observable<M.TeacherCampus[]> {
    return this.http.get<M.TeacherCampus[]>(`${environment.gatewayUrl}/filters/TeacherCampuses`);
  }

  getTeacherSemesters(filterType: E.TeacherSemestersFilterType = E.TeacherSemestersFilterType.None, includeFuture?: boolean): Observable<M.Semester[]> {
    let url = `${environment.gatewayUrl}/filters/TeacherSemesters?filterType=${filterType}`
    if (includeFuture) {
      url += `&includeFuture=true`;
    }
    return this.http.get<M.Semester[]>(url);
  }

  getAbroadCourseAssignments(abroadCourseId: number): Observable<M.AbroadCourseAssignment[]> {
    return this.http.get<M.AbroadCourseAssignment[]>(`${environment.gatewayUrl}/filters/AbroadCourseAssignments?abroadCourseId=${abroadCourseId}`);
  }

  getGroupTeachers(groupId: number): Observable<M.GroupTeacher[]> {
    return this.http.get<M.GroupTeacher[]>(`${environment.gatewayUrl}/filters/GroupTeachers?groupId=${groupId}`);
  }

  teacherScheduleDstDates(): Observable<M.TeacherScheduleDstDates[]> {
    return this.http.get<M.TeacherScheduleDstDates[]>(`${environment.gatewayUrl}/filters/TeacherScheduleDstDates`);
  }

  updateTeacherProfile(profile?: M.TeacherProfile): Observable<M.TeacherProfile> {
    return this.http.post<M.TeacherProfile>(`${environment.gatewayUrl}/teachers/profile`, profile);
  }

  getTeacherStudentsByCourseId(courseId?: number): Observable<M.StudentByCourse[]> {
    return this.http.get<M.StudentByCourse[]>(`${environment.gatewayUrl}/filters/CourseStudents?courseId=${courseId}`);
  }

  getListCourseAcademicStudents(courseId: number): Observable<M.CourseAcademicStudents[]> {
    return this.http.get<M.CourseAcademicStudents[]>(`${environment.gatewayUrl}/subsAndCancels/ListCourseAcademicStudents?courseId=${courseId}`);
  }

  login(usernameOrEmail: string, password: string, selectedMemberIndex?: number): Observable<M.LoginResponse> {
    return this.http.post<M.LoginResponse>(`${environment.gatewayUrl}/login`, { usernameOrEmail, password, selectedMemberIndex });
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${environment.gatewayUrl}/Login/ForgotPassword`, { email });
  }

  resetPassword(email: string, userName: string, newPassword: string, resetToken: string): Observable<any> {
    return this.http.post<any>(`${environment.gatewayUrl}/Login/ResetPassword`, { email, userName, newPassword, resetToken });
  }

  resetPasswordFromCrm(email: string, memberId: number): Observable<any> {
    return this.http.post<any>(`${environment.gatewayUrl}/Login/ResetPasswordFromCrm`, { email, memberId });
  }

  sendEmailForCreatePassword(email: string, memberId: number): Observable<any> {
    return this.http.post<any>(`${environment.gatewayUrl}/Users/SendEmailForCreatePassword`, { email, memberId });
  }

  getNextSchedule(teacherId?: number): Observable<M.NextSchedule> {
    return this.http.get<M.NextSchedule>(`${environment.gatewayUrl}/schedule/Next`);
  }

  getSchedule(from: number, to: number, filterType: E.ScheduleFilterType = E.ScheduleFilterType.None): Observable<M.Lesson[]> {
    return this.http.get<M.Lesson[]>(`${environment.gatewayUrl}/schedule/get?startDate=${from}&endDate=${to}&filterType=${filterType}`);
  }

  UpdateCourseConfirmationStatus(item: M.UpdateCourseConfirmationStatus): Observable<any> {
    return this.http.post(`${environment.gatewayUrl}/Schedule/UpdateCourseConfirmationStatus`, item);
  }

  private buildDate(date: number, hourAndMin: number): number {
    var dateAsObject = new Date(date);
    var hourAndMinAsObject = new Date(hourAndMin);
    dateAsObject.setHours(hourAndMinAsObject.getHours());
    dateAsObject.setMinutes(hourAndMinAsObject.getMinutes());
    return dateAsObject.getTime();

  }

  getSubsAndCancelsSummary(): Observable<M.SubsAndCancelsSummary> {
    return this.http.get<M.SubsAndCancelsSummary>(`${environment.gatewayUrl}/subsAndCancels/summary`);
  }

  getActivitySummary(): Observable<M.ActivitySummary> {
    return this.http.get<M.ActivitySummary>(`${environment.gatewayUrl}/Activity/Summary`);
  }

  getBirthdays(from: Date, to: Date, teacherId?: number): Observable<M.Birthday[]> {
    return this.http.get<M.Birthday[]>(`${environment.gatewayUrl}/birthdays/list?from=${from.getTime()}&to=${to.getTime()}`);
  }

  sendBirthdayGreeting(data: M.BirthDayGreeting) {
    return this.http.post(`${environment.gatewayUrl}/birthdays/SendGreeting`, data);
  }

  getMemberImage(id: number): Observable<string> {
    // return of(`/assets/icons/user.svg`);
    return of(`https://static.eteachergroup.com/images/teachersimages/${id}.jpg`);
    // return this.http.get(`${environment.gatewayUrl}/images/${id}`, { responseType: 'text' });

    // return this.http.get(`https://static.eteachergroup.com/images/teachersimages/${id}.jpg`, { responseType: 'text' });
  }

  // Tickets

  getOpenedTickets(): Observable<M.Ticket[]> {
    return this.http.get<M.Ticket[]>(`${environment.gatewayUrl}/Tickets/Opened`);
  }

  getClosedTickets(startDate?: number, endDate?: number, searchText?: string): Observable<M.Ticket[]> {
    let url = `${environment.gatewayUrl}/Tickets/Closed`;
    // url += startDate != 0 ? `&startDate=${startDate}` : '';
    // url += endDate != 0 ? `&endDate=${endDate}` : '';
    // url += searchText !="" ? `&searchText=${searchText}` : '';
    if (startDate != 0) {
      url += `?startDate=${startDate}&endDate=${endDate}&searchText=${searchText}`;
    }
    else {
      url += searchText != "" ? `?searchText=${searchText}` : '';
    }
    return this.http.get<M.Ticket[]>(url);
    //return this.http.get<M.Ticket[]>(`${environment.gatewayUrl}/Tickets/Closed?startDate=${startDate}&endDate=${endDate}&searchText=${searchText}`);
  }

  getTicketDetails(ticketId: number): Observable<M.Ticket> {
    return this.http.get<M.Ticket>(`${environment.gatewayUrl}/Tickets/Ticket?ticketId=${ticketId}`);
  }

  sendNewTicketComment(newComment: M.NewComment): Observable<string> {
    return this.http.post<string>(`${environment.gatewayUrl}/Tickets/TicketComment`, newComment);
  }

  openTicket(newComment: M.NewTicket): Observable<any> {
    return this.http.post<any>(`${environment.gatewayUrl}/Tickets/Ticket`, newComment);
  }

  getFile(fileId: number): Observable<Blob> {
    return this.http.get(`${environment.gatewayUrl}/Files/GetFile?fileId=${fileId}`, { responseType: 'blob' });
  }

  // Payments Reports

  getPaymentSummary(month: number, year: number): Observable<M.PaymentSummary> {
    return this.http.get<M.PaymentSummary>(`${environment.gatewayUrl}/Payment/Summary?month=${month}&year=${year}`)
  }

  getPaymentsTabs(): Observable<M.PaymentTabs> {
    return this.http.get<M.PaymentTabs>(`${environment.gatewayUrl}/Payment/GetPaymentsTabs`);
  }

  getLessonsToReport(month: number, year: number, courseId?: number, groupId?: number): Observable<M.LessonsToReport> {
    let url = `${environment.gatewayUrl}/Payment/LessonsToReport?month=${month}&year=${year}`;
    url += courseId ? `&courseId=${courseId}` : '';
    url += groupId ? `&groupId=${groupId}` : '';
    return this.http.get<M.LessonsToReport>(url)
  }

  getPrivateLessonsToReport(): Observable<M.PrivateLessonsToReport> {
    let url = `${environment.gatewayUrl}/Payment/PrivateLessonsToReport`;
    // let url = `${environment.gatewayUrl}/Payment/PrivateLessonsToReport?month=${month}&year=${year}`;
    return this.http.get<M.PrivateLessonsToReport>(url)
  }
  getReportedPrivateLessons(month: number, year: number): Observable<M.PrivateLessonsToReport> {
    let url = `${environment.gatewayUrl}/Payment/ReportedPrivateLessons?month=${month}&year=${year}`;
    return this.http.get<M.PrivateLessonsToReport>(url)
  }

  getReportedLessons(month: number, year: number, courseId?: number, groupId?: number): Observable<M.ReportedLessons> {
    let url = `${environment.gatewayUrl}/Payment/ReportedLessons?month=${month}&year=${year}`;
    url += courseId ? `&courseId=${courseId}` : '';
    url += groupId ? `&groupId=${groupId}` : '';
    return this.http.get<M.ReportedLessons>(url)
  }

  reportLessons(reportLessons: M.ReportLessons, month: number, year: number, courseId?: number, groupId?: number): Observable<M.ReportedLessons> {
    let url = `${environment.gatewayUrl}/Payment/ReportLessons?month=${month}&year=${year}`;
    url += courseId ? `&courseId=${courseId}` : '';
    url += groupId ? `&groupId=${groupId}` : '';
    return this.http.post<M.ReportedLessons>(url, reportLessons);
  }

  reportPrivateLessons(reportLessons: M.ReportLessons): Observable<M.PrivateLessonsReportsResult> {
    let url = `${environment.gatewayUrl}/Payment/ReportPrivateLessons`;
    return this.http.post<M.PrivateLessonsReportsResult>(url, reportLessons);
  }

  getDevMarketingReport(month: number, year: number): Observable<M.DevMarketingReports> {
    return this.http.get<M.DevMarketingReports>(`${environment.gatewayUrl}/Payment/DevMarketingReports?month=${month}&year=${year}`)
  }

  getListExtraSalaryItems(): Observable<Array<M.DevMarketingSubject>> {
    return this.http.get<Array<M.DevMarketingSubject>>(`${environment.gatewayUrl}/Payment/ListExtraSalaryItems`);
  }

  addExtraSalaryItemReport(reportItem: M.ExtraSalaryItemReport) {
    return this.http.post<string>(`${environment.gatewayUrl}/Payment/AddExtraSalaryItemReport`, reportItem, { responseType: 'text' as 'json' });
  }

  deleteExtraSalaryItemReports(reportIds: number[]) {
    return this.http.post<string>(`${environment.gatewayUrl}/Payment/DeleteExtraSalaryItemReports`, reportIds, { responseType: 'text' as 'json' });
  }

  getCountries(): Observable<Array<M.Country>> {
    return this.http.get<Array<M.Country>>(`${environment.gatewayUrl}/Settings/countries`);
  }

  getLanguages(): Observable<Array<M.Language>> {
    return this.http.get<Array<M.Language>>(`${environment.gatewayUrl}/Settings/languages`);
  }

  deletePrivateLessonReports(reportIds: number[]) {
    return this.http.post<string>(`${environment.gatewayUrl}/Payment/DeletePrivateLessonReports`, reportIds);
  }

  getAcademicActivity(month: number, year: number): Observable<M.AcademicActivity> {
    return this.http.get<M.AcademicActivity>(`${environment.gatewayUrl}/Payment/AcademicActivity?month=${month}&year=${year}`)
  }

  getContinuingStudentsByMonth(month: number, year: number): Observable<M.ContinuingStudents> {
    return this.http.get<M.ContinuingStudents>(`${environment.gatewayUrl}/Payment/ContinuingStudentsByMonth?month=${month}&year=${year}`)
  }

  getContinuingStudentsBySemetser(semesterId: number): Observable<M.ContinuingStudents> {
    return this.http.get<M.ContinuingStudents>(`${environment.gatewayUrl}/Payment/ContinuingStudentsBySemester?semesterId=${semesterId}`)
  }
  getNeededSubstitutions(): Observable<M.NeededSubstitution[]> {
    return this.http.get<M.NeededSubstitution[]>(`${environment.gatewayUrl}/subsAndCancels/NeededSubstitutions`);
  }
  getSubstitutionRequests(): Observable<M.SubstitutionRequest[]> {
    return this.http.get<M.SubstitutionRequest[]>(`${environment.gatewayUrl}/subsAndCancels/SubstitutionRequests`);
  }
  getSubstitutions(): Observable<M.Substitution[]> {
    return this.http.get<M.Substitution[]>(`${environment.gatewayUrl}/subsAndCancels/Substitutions`);
  }

  getCoursesForMarathons(): Observable<M.ClassForMaraton[]> {
    return this.http.get<M.ClassForMaraton[]>(`${environment.gatewayUrl}/subsAndCancels/CoursesForMarathons`);
  }

  deleteScheduleRequest(scheduleRequestId: number): Observable<M.ClassForMaraton[]> {
    return this.http.post<M.ClassForMaraton[]>(`${environment.gatewayUrl}/subsAndCancels/DeleteScheduleRequest`, { scheduleRequestId });
  }

  addUpdateSubstitutionRequest(item: M.AddUpdateSubstitutionRequests) {
    return this.http.post<M.AddUpdateSubstitutionRequestsResponse>(`${environment.gatewayUrl}/subsAndCancels/SubstitutionRequests`, item);
  }
  getsubsAndCancelsTeacherCourses(): Observable<M.Substitution_TeacherCourse[]> {
    return this.http.get<M.Substitution_TeacherCourse[]>(`${environment.gatewayUrl}/filters/TeacherCourses?filterType=${E.TeacherCoursesFilterType.Substitutions}`)
  }

  getCoursesForMakeupLesson() {
    return this.http.get<M.CourseForMakeupLesson[]>(`${environment.gatewayUrl}/subsAndCancels/CoursesForMakeupLesson`)
  }

  getListMyScheduleChanges() {
    return this.http.get<M.MyScheduleChange[]>(`${environment.gatewayUrl}/subsAndCancels/ListMyScheduleChanges`)
  }

  addUpdateScheduleRequest(newSchedule: M.AddUpdateScheduleRequest) {
    return this.http.post<M.ScheduleRequestResult>(`${environment.gatewayUrl}/subsAndCancels/AddUpdateScheduleRequest`, newSchedule)
  }

  addUpdateCourseReplacementTeachers(item: M.AddCourseReplacementTeacher): Observable<string> {
    return this.http.post<string>(`${environment.gatewayUrl}/subsAndCancels/AddUpdateCourseReplacementTeachers`, item);
  }
  UpdateSubstitutionRequestReplacementTeacher(item: M.UpdateSubstitutionRequestReplacementTeacher): Observable<string> {
    return this.http.post<string>(`${environment.gatewayUrl}/subsAndCancels/UpdateSubstitutionRequestReplacementTeacher`, item);
  }
  DeleteSubstitutionRequest(item: M.DeleteSubstitutionRequest): Observable<string> {
    return this.http.post<string>(`${environment.gatewayUrl}/subsAndCancels/DeleteSubstitutionRequestRequest`, item);
  }
  getNotifications(): Observable<M.Notification[]> {
    return this.http.get<M.Notification[]>(`${environment.gatewayUrl}/Notifications/Get`);
  }
  checkUserImage(id: number) {
    return this.http.get(`https://static.eteachergroup.com/images/teachersimages/${id}.jpg`)
  }
  getListTeacherOnAirLessons(): Observable<M.TeacherOnAirLessons[]> {
    return this.http.get<M.TeacherOnAirLessons[]>(`${environment.gatewayUrl}/Courses/ListTeacherOnAirLessons`);
  }
  getEnterLessonUrl(id: number): Observable<any> {
    return this.http.get<any>(`${environment.gatewayUrl}/Courses/GetEnterLessonUrl/${id}`);
  }
  getClassesToApproveCount(): Observable<any> {
    return this.http.get<any>(`${environment.gatewayUrl}/Schedule/GetClassesToApproveCount`);
  }
  getPublishedClassesForConfirmationCount(): Observable<any> {
    // return this.http.get<any>(`${environment.gatewayUrl}/Courses/GetPublishedClassesForConfirmationCount`);
    return this.http.get<any>(`${environment.gatewayUrl}/Courses/GetPublishedClassesForConfirmation`);
  }
  getStudentForExamScheduleCount(): Observable<any> {
    return this.http.get<any>(`${environment.gatewayUrl}/SubsAndCancels/GetStudentForExamScheduleCount`);
  }
  getListPendingInfoForReorder(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.gatewayUrl}/Students/ListPendingInfoForReorder`);
  }

  getClasses() {
    return this.http.get<M.Classes>(`${environment.gatewayUrl}/Courses/GetClasses`);
  }

  addTempFile(tempFileData: M.TempFileJsonViewModel) {
    return this.http.post<{ tempFileId: number, fileError: E.FileError }>(`${environment.gatewayUrl}/Files/AddTempFile`, tempFileData);
  }

  getTeacherSemestersData(groupId: number, year: number) {
    return this.http.get<M.SemesterData[]>(`${environment.gatewayUrl}/Courses/ListSemesters?groupId=${groupId}&year=${year}`);
  }

  getTeacherOpenClasses(groupId: number) {
    return this.http.get<M.OpenClassLite[]>(`${environment.gatewayUrl}/Courses/ListOpenClasses?groupId=${groupId}`);
  }

  getCourseListRecordings(courseId: number) {
    return this.http.get<M.Recording[]>(`${environment.gatewayUrl}/Courses/ListRecordings?courseId=${courseId}`);
  }

  getCourseListMessages(courseId: number) {
    return this.http.get<M.CourseMessage[]>(`${environment.gatewayUrl}/Courses/ListMessages?courseId=${courseId}`);
  }

  deleteCourseMessage(messageId: number) {
    return this.http.post<M.CourseMessage[]>(`${environment.gatewayUrl}/Courses/DeleteCourseMessage?messageId=${messageId}`, messageId);
  }

  addUpdateCourseMessage(courseId: number, message: M.CourseMessage) {
    return this.http.post<M.CourseMessage[]>(`${environment.gatewayUrl}/Courses/AddUpdateCourseMessage?courseId=${courseId}`, message);
  }

  updateCourseMaterials(data: M.CourseMaterialsForPublishing) {
    return this.http.post<string>(`${environment.gatewayUrl}/Courses/UpdateCourseMaterials`, data);
  }

  updateAbroadCourseMaterials(data: M.CourseMaterialsForPublishing) {
    return this.http.post<string>(`${environment.gatewayUrl}/Courses/UpdateAbroadCourseMaterials`, data);
  }

  updateUnitItems(data: M.UnitItemsForPublishing) {
    return this.http.post<string>(`${environment.gatewayUrl}/Courses/UpdateUnitItems`, data);
  }

  getListAbroadCourseMaterials(courseId: number, abroadCourseId: number) {
    return this.http.get<M.CourseMaterialItem[]>(`${environment.gatewayUrl}/Courses/ListAbroadCourseMaterials?courseId=${courseId}&abroadCourseId=${abroadCourseId}`);
  }

  getListMaterials(courseId: number) {
    return this.http.get<M.CourseMaterial[]>(`${environment.gatewayUrl}/Courses/ListMaterials?courseId=${courseId}`);
  }

  getListUnits(courseId: number) {
    return this.http.get<M.CourseUnitView[]>(`${environment.gatewayUrl}/Courses/ListUnits?courseId=${courseId}`);
  }

  deleteCourseMaterial(courseDataId: number) {
    return this.http.post<string>(`${environment.gatewayUrl}/Courses/DeleteCourseMaterial?courseDataId=${courseDataId}`, { courseDataId });
  }

  addUpdateCourseMaterial(courseId: number, courseMaterial: M.CourseMaterial, tempFileId?: number) {
    let url = `${environment.gatewayUrl}/Courses/AddUpdateCourseMaterial?courseId=${courseId}`;
    url += tempFileId ? `&tempFileId=${tempFileId}` : '';
    return this.http.post<string>(url, courseMaterial);
  }

  getObserverProducts() {
    return this.http.get<M.TeacherCampus[]>(`${environment.gatewayUrl}/filters/TeacherCampuses`);
  }

  getObserverSemesters(groupId: number, teacherId: number) {
    return this.http.get<M.Semester[]>(`${environment.gatewayUrl}/filters/TeacherSemesters?filterType=3&groupId=${groupId}&teacherId=${teacherId}`);
  }

  getObserverTeacherCourses(groupId: number, teacherId: number, semesterIds: string) {
    return this.http.get<M.Course[]>(`${environment.gatewayUrl}/filters/TeacherCourses?filterType=3&groupId=${groupId}&teacherToObserve=${teacherId}&courseType=1&semesterIds=${semesterIds}`);
  }

  getListTeacherCourses(groupId: number, teacherId: number, classType: number, semesterIds: string, courseId: number) {
    return this.http.get<M.ObCourse[]>(`${environment.gatewayUrl}/Observations/ListTeacherCourses?groupId=${groupId}&teacherId=${teacherId}&classType=${classType}&semesterIds=${semesterIds}&courseId=${courseId}`);
  }

  getListCourseRecordings(courseId: number) {
    return this.http.get<M.ObRecording[]>(`${environment.gatewayUrl}/Observations/ListCourseRecordings?courseId=${courseId}`);
  }

  getObservationsTeacherProfile(teacherId: number) {
    return this.http.get<M.ObTeacher>(`${environment.gatewayUrl}/Observations/TeacherProfile?teacherId=${teacherId}`);
  }

  setObservedRecording(lessonId: number) {
    return this.http.post<string>(`${environment.gatewayUrl}/Observations/SetObservedRecording?lessonId=${lessonId}`, { lessonId });
  }

  getAllTeacherStudents(): Observable<M.TeacherStudent[]> {
    return this.http.get<M.TeacherStudent[]>(`${environment.gatewayUrl}/filters/TeacherStudents`);
  }

  getTrainingProducts() {
    return this.http.get<M.TeacherCampus[]>(`${environment.gatewayUrl}/filters/TeacherCampuses`);
  }

  getTrainingTeacherCourses(groupId: number, teacherId: number) {
    return this.http.get<any[]>(`${environment.gatewayUrl}/Training/TeacherCourses?groupId=${groupId}&teacherId=${teacherId}`);
  }

  getUtcOffsetAtDate(date: number) {
    return this.http.get<number>(`${environment.gatewayUrl}/Teachers/GetUtcOffsetAtDate?date=${date}`);
  }

  getListNationalGeographicCourses(): Observable<M.NationalGeographicCourse[]> {
    return this.http.get<M.NationalGeographicCourse[]>(`${environment.gatewayUrl}/Courses/ListNationalGeographicCourses`);
  }

  getListCourseReportsSummary(courseId: number) {
    return this.http.get<M.CourseReportSummary[]>(`${environment.gatewayUrl}/Courses/ListCourseReportsSummary?courseId=${courseId}`);
  }

  getListForums() {
    return this.http.get<M.Forum[]>(`${environment.gatewayUrl}/Forums/ListForums`);
  }

  getForumPosts(forumId: number) {
    return this.http.get<M.ForumPost[]>(`${environment.gatewayUrl}/Forums/ForumPosts?forumId=${forumId}`);
  }

  addForumPost(data: M.ForumPostData): Observable<any> {
    return this.http.post<any>(`${environment.gatewayUrl}/Forums/AddForumPost`, data);
  }

  deleteForumPost(forumId: number): Observable<any> {
    return this.http.post<any>(`${environment.gatewayUrl}/Forums/DeleteForumPost?postId=${forumId}`, {});
  }

  getPrivateLessonScheduleInfor(courseId:number):Observable<M.CourseSchedulePrivate> {
    return this.http.get<M.CourseSchedulePrivate>(`${environment.gatewayUrl}/schedule/GetPrivateLessonScheduleInfor?courseId=${courseId}`);
  }
  getTrainingRecordings(courseId: number) {
    return this.http.get<M.TrainingRecording[]>(`${environment.gatewayUrl}/Training/ListCourseRecordings?courseId=${courseId}`);
  }
}
