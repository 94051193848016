import { Observable, defer } from 'rxjs';
import { publishReplay, refCount, take } from 'rxjs/operators';



export const cacheTime = (api: Observable<any>, time: number) => defer(() => api)
    .pipe(
        publishReplay(1, time),
        refCount(),
        take(1)
    );