import { Injectable } from '@angular/core';
import * as M from '../../app.models';
import * as E from '../../app.enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { NetService } from '../net/net.service';
import { tap } from 'rxjs/operators';
import { PersistenceService } from '../persistence/persistence.service';
import { StorageKey } from 'src/app/app.enums';
import { cacheTime } from '../custom-operators.helper';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {
  private _teacherProfile$ = new BehaviorSubject<M.TeacherProfile | undefined>(undefined);
  private _teacherSemesters$ = new BehaviorSubject<M.Semester[] | undefined>(undefined);
  getTeacherProfile$: Observable<M.TeacherProfile> = cacheTime(this.getTeacherProfile(0), E.Static.HTTP_RELOAD_TIME);
  public listDates: { key: number, value: string }[] = [];

  get teacherProfile$(): BehaviorSubject<M.TeacherProfile | undefined> {
    if (!this._teacherProfile$.value) {
      this.fetachTeacherProfile();
    }
    return this._teacherProfile$;
  }
  
  get teachersemeter$(): BehaviorSubject<M.Semester[] | undefined> {
    return this._teacherSemesters$;
  }

  constructor(private net: NetService, private persistService: PersistenceService) { }

  getTeacher(id?: number): Observable<M.Teacher> {
    return this.net.getTeacher(id).pipe(tap((x) => {
      this.persistService.save(StorageKey.UtcOffset, x.utcOffset);
    }));
  }
  
  getTeacherProfile(id: number): Observable<M.TeacherProfile> {
    return this.net.getTeacherProfile(id).pipe(tap(teacherProfile => {
      this._teacherProfile$.next(teacherProfile)
    }));
  }

  getTeacherCourses(filter?: E.TeacherCoursesFilterType, semesterId?: number): Observable<M.TeacherCourse[]> {
    return this.net.getTeacherCourses(filter, semesterId);
  }

  getTeacherSemesters(filter?: E.TeacherSemestersFilterType, includeFuture? :boolean): Observable<M.Semester[]> {
    return this.net.getTeacherSemesters(filter, includeFuture).pipe(tap(res => this._teacherSemesters$.next(res)));
  }

  getTeacherCampuses(): Observable<M.TeacherCampus[]> {
    return this.net.getTeacherCampuses();
  }

  getTeacherStudentsByCourseId(courseId: number): Observable<M.StudentByCourse[]> {
    return this.net.getTeacherStudentsByCourseId(courseId);
  }
  getListCourseAcademicStudents(courseId: number): Observable<M.CourseAcademicStudents[]> {
    return this.net.getListCourseAcademicStudents(courseId);
  }

  updateTeacherProfile(profile: M.TeacherProfile) {
    return this.net.updateTeacherProfile(profile);
  }

  fetachTeacherProfile(): void {
    this.getTeacherProfile$
      .subscribe(teacherProfile => this._teacherProfile$.next(teacherProfile));
  }

  clearCache(){
    this._teacherProfile$.next(undefined);
    this.listDates = [];
  }

  getUtcOffsetAtDate(date: number){
    return this.net.getUtcOffsetAtDate(date);
  }
}
