import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import * as M from '../../app.models';
import { NetService } from '../net/net.service';
import { TeacherService } from '../teacher/teacher.service';
import { StudentService } from '../student/student.service';
import { PersistenceService } from '../persistence/persistence.service';
import { NotificationsService } from '../notifications/notifications.service';
import { ClassesService } from '../classes/classes.service';
import { CourseService } from '../course/course.service';
import { FiltersService } from '../filters/filters.service';
import { PaymentService } from '../payment/payment.service';
// import { ScheduleService } from '../schedule/schedule.service';
import * as E from '../../app.enums';
import { Router } from '@angular/router';
// import { SubsAndCancelsService } from '../subs-and-cancels/subs-and-cancels.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private _loggedInTeacher$: BehaviorSubject<
    M.Teacher | undefined
  > = new BehaviorSubject<M.Teacher | undefined>(undefined);
  private _token?: string;
  public logout$: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);

  /**
   * The URL that was requested but the user wasn't logged in.
   * This is where we'll take the user after he/she logs in.
   */
  redirectUrl: string | undefined;

  get loggedInTeacher$(): BehaviorSubject<M.Teacher | undefined> {
    if (this.isLoggedIn && !this._loggedInTeacher$.value) {
      this.fetchTeacher();
    }
    return this._loggedInTeacher$;
  }

  get token(): string | undefined {
    return this._token;
  }

  get isLoggedIn(): boolean {
    return !!this._token;
  }

  constructor(
    private net: NetService,
    private teacher: TeacherService,
    private studentService: StudentService,
    private persistenceService: PersistenceService,
    private notificationsService: NotificationsService,
    private classesService: ClassesService,
    private courseService: CourseService,
    private filtersService: FiltersService,
    private paymentService: PaymentService,
    // private subsAndCancelsService: SubsAndCancelsService,
    // private scheduleService: ScheduleService,
    private router: Router
  ) {
    this._token = this.persistenceService.load(E.StorageKey.Token);
  }

  login(usernameOrEmail: string, password: string): Observable<M.Teacher> {
    return this.net.login(usernameOrEmail, password).pipe(switchMap(loginResponse => {
      this._token = loginResponse.token;
      this.persistenceService.save(E.StorageKey.Token, this._token);

      return this.teacher.getTeacher()
        .pipe(tap(teacher => this._loggedInTeacher$.next(teacher)));
    }));
  }

  fetchTeacher(): void {
    this.teacher
      .getTeacher()
      .subscribe((teacher) => this._loggedInTeacher$.next(teacher));
  }

  forgotPassword(email: string): Observable<any> {
    return this.net.forgotPassword(email);
  }

  resetPassword(email: string, userName: string, newPassword: string,resetToken: string): Observable<any> {
    return this.net.resetPassword(email, userName, newPassword, resetToken);
  }

  resetPasswordFromCrm(email:string, memberId: number): Observable<any> {
    return this.net.resetPasswordFromCrm(email,memberId);
  }

  logOut() {
    // this.logOutWithoutRedirect();
    // this.router.navigate(['/login'])
    
    this.logOutWithoutRedirect();
    window.location.href = '/login';
  }

  logOutWithoutRedirect() {
    this.persistenceService.save(E.StorageKey.Token, null);
    this.studentService.clearCache();
    this.notificationsService.clearCache();    
    this.classesService.clearCache();
    this.filtersService.clearCache();
    this.courseService.clearCache();
    this.paymentService.clearCache();
    // this.subsAndCancelsService.clearCache();
    // this.scheduleService.clearCache();
    this.logout$.next(true);
    this.teacher.clearCache();
  }

  // login(usernameOrEmail: string, password: string): Observable<M.Teacher> {
  //   this._token = 'This is a fake token';
  //   this.persistenceService.save(E.StorageKey.Token, this._token);
  //   const myTeacher: M.Teacher = this.getMyTeacher();
  //   this._loggedInTeacher$.next(myTeacher);
  //   return new Observable((o) => {
  //     o.next(myTeacher);
  //   });
  // }

  // getMyTeacher(): M.Teacher {
  //   return {
  //     id: 123123,
  //     firstName: 'firstName',
  //     lastName: 'lastName',
  //     fullName: 'fullName',
  //     photo: 'photo',
  //     city: 'city',
  //     country: 'country',
  //     gender: E.Gender.Female,
  //     productBrands: [],
  //     languageCode: 'languageCode',
  //   };
  // }
}
