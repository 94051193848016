import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../../services/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private loginService: LoginService, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin(state.url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin(state.url);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLoginAndRedirect(this.router.getCurrentNavigation()?.extractedUrl.toString());
  }

  checkLogin(url: string): true | UrlTree {
    if (this.loginService.isLoggedIn) { return true; }
    this.loginService.redirectUrl = url;
    return this.router.parseUrl('/login');
  }

  checkLoginAndRedirect(url: string | undefined): Promise<boolean> {
    const checkLoginResult = this.checkLogin(url || '');
    if (checkLoginResult instanceof UrlTree) {
      this.router.navigateByUrl(checkLoginResult);
      return new Promise<boolean>(resolve => resolve(false));
    }

    return new Promise<boolean>(resolve => resolve(true));
  }
}
