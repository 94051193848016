export enum Gender {
    Male = 0,
    Female = 1
}

export enum GeneralAssessment {
    DontKnowThisStudent = 0,
    FarBelowAverage = 1,
    BelowAverage = 2,
    Fair = 3,
    VeryGood = 4,
    Excellent = 5,					   
}

export enum ProductType {
    None = 0,
    Expats = 1,
    Modern = 2,
    MFA = 3,
    Intel = 4,
    Biblical = 5,
    Chinese = 6,
    English = 7,
    Private = 8,
    Yiddish = 10,
    Aramaic = 11,
    Greek = 12,
    Nitzan = 13,
    JBNT = 14,
    JGAB = 15,
    ELHB = 16,
    ECRST = 17,
    JNAG = 18,
    DHB = 19,
    COD = 20,
    Career = 21,
    WTP = 22,
    YouTube = 23,
    EFK = 24
}

export enum ExtraSalaryItemReportType {
    Item = 1,
    Hourly = 2,
}

export enum ReportPrivateLessonError {
    None = 0,
    ReportTimeExists = 1
}

export enum StorageKey {
    Token = 'et_at',
    Language = 'et_lang',
    UtcOffset = 'et_utc_offset',
    selectedStudentID = 'et_si',
    notificationClicked = 'et_nc',
    lastestLink = 'et_ll',
    selectedCourseID = 'et_ci',
    isEditing = 'et_ie',
}

export enum ViewLessonType {
    PRIVATE_LESSONS,
    COURSES
}

export enum PaymentTableType {
    LESSONS_TO_REPORT,
    REPORTED_LESSONS
}

export enum DisplayDirection {
    LeftToRight,
    RightToLeft
}

export enum NextScheduleType {
    Group = 1,
    PrivateLesson = 2,
    Webinar = 3,
    OpenClass = 4,
    Training = 5
}

export enum ClassType {
    Group = 1,
    Private = 2,
    Open = 3,
    Webinar = 4,
    Training = 5
}

export enum NextScheduleStatus {
    Live = 1,
    Future = 2
}

export enum ScheduleRequestError {
    None = 0,
    TeacherBusy = 1,
    NoCancelledLesson = 2,
    AfterSessionEnd = 3,
    InvalidWeekday = 4,
    InvalidDate = 5,
    ExceedsLength = 6,
    InvalidEndTime = 7,
    LessThanFourHours = 8,
    RequestExists = 9
}

export enum ScheduleItemType {
    Group = 1,
    Private = 2,
    OpenClass = 3,
    Webinar = 4,
    Training = 5,
    Vacation = 6,
    Substitution = 7,
    ScheduleChange = 8,
    MakeUp = 9,
    Exam = 10,
    Marathon = 11
}

export enum ScheduleItemConfirmationStatus {
    None = -1,
    Rejected = 0,
    Approved = 1
}

export enum ScheduleItemStatus {
    Past,
    Active,
    Future
}

export enum PerformanceMessage {
    None,
    GreatJob,
    FairEnough,
    YouCanDoBetter
}

export enum UserType {
    Teacher = 1,
    Student = 2
}

export enum ScheduleMode {
    Month = 'Month',
    Week = 'Week'
}

export enum TeamLevel2 {
    TechnicalSupport = 7,
    CustomerCare = 4
}

export enum Issue {
    Audio = 8,
    Connection = 10,
    Recordings = 13,
    Software = 14,
    ProblemEnteringClass = 40,
    StudyMaterials = 2,
    TheStudentDoesNotRespond = 52,
    LessonCancellation = 4,
    InboundMessage = 7,
    ShippingProblem = 93,
    LoginProblem = 100,
    ClassLevel = 1,
    ScheduleEnquiries = 26
}

export enum RegardingWho {
    Teacher = 1,
    Students = 2
}

export enum Urgency {
    Morning = 1,
    Noon = 2,
    Evening = 3,
    Now = 4,
    Asap = 5,
    LaterOn = 6
}

export enum LessonCancelReason {
    None = 0,
    TeacherDidNotCome = 1,
    StudentsDidNotArrive = 2,
    EteacherServerProblem = 3,
    ProblemWithTeacher = 4,
    ProblemWithStudents = 5,
    KnownInAdvance = 6,
    HolidayOrVacation = 7,
    SemesterHasNotStarted = 8,
    SemesterHasEnded = 9,
    NoSubstitutionFound = 10,
}

export enum PrivateLessonCancelReasons {
    None = 0,
    StudentDidNotArrive = 1,
    PartialStudentTechnicalProblem = 2,
    PartialTeacherTechnicalProblem = 3,
    PartialServerProblem = 4,
    StudentWasLate = 5,
    StudentTechnicalProblem = 6,
    TeacherTechnicalProblem = 7,
    ServerProblem = 8,
    StudentDidNotArriveLateNotification = 9
}

export enum HoursReportConfirmType {
    Unconfirmed = 0,
    TookPlace = 1,
    LessonCancelled = 2,
    PartlyTookPlace = 3
}

export enum AdditionalLanguageLevelId {
    Basic = 1,
    Intermediate = 2,
    Fluent = 3,
    Mother_Tongue = 4
}

export enum Language {
    'None' = 0,
    'Hebrew' = 100,
    'English' = 101,
    'French' = 102,
    'Russian' = 103,
    'Spanish' = 104,
    'Arabic' = 105,
    'Portuguese' = 106,
    'German' = 107,
    'Turkish' = 108,
    'Korean' = 109
}

export enum FileError {
    None = 0,
    Type = 1,
    DisallowedExtension = 2,
    MaxSize = 3,
    FileName = 4
}

export enum ScheduleFilterType {
    None,
    MakeUp,
    Reschedule, // schedule change step 1 calendar
    ScheduleChange, // schedule change step 2 calendar
    Exam,
    Marathon
}

export enum TeacherCoursesFilterType {
    None,
    Substitutions,
    AcademicStudents
}

export enum TeacherSemestersFilterType {
    None,
    AcademicStudents,
    ContinuingStudents
}

export enum NotificationType {
    Message = 1,
    Link = 2,
    PoliceConfirmation = 3,
    AcademicInformation = 4,
    ConfirmClasses = 5,
    Substitutions = 6,
    EndOfSemester = 7,
    ScheduleChange = 8

}
export enum SubRequestStatus {
    Disable,
    Unavailable,
    Available,
    Selected
}
export enum ScheduleFilterType {
    ByDate = 1,
    ByCourse = 2,
}

export enum SubsAndChangesPrimaryLabel {
    Substitutions = 'substitutions',
    ScheduleChanges = 'schedule-changes',
}
export enum Substitutions {
    Needed = 'needed',
    Requests = 'my-requests',
    Substituting = 'substituting',
}
export enum ScheduleChanges {
    LessonToMakeUp = 'lesson-to-make-up',
    RescheduleLesson = 'reschedule',
    ScheduleExam = 'schedule-exam',
    ScheduleMarathon = 'schedule-marathon',
    MyScheduleChanges = 'my-schedule-changes'
}
export enum substitutionRequestError {
    none = 0,
    exists = 1,
    vacationOrOutOfSemester = 2,
    invalidDate = 3,
}
export enum CourseType {
    Group = 1,
    PrivateLesson = 2,
    OpenClass = 3,
    TrainingClass = 4,
    Webinar = 5
}

export enum CourseLessonType {
    MakeUp = 1,
    ScheduleChange = 2,
    Exam = 3,
    Marathon = 4
}

export enum ScheduleRequestRequestedBy {
    Teacher = 1,
    Student = 2
}

export enum InfoVariations {
    PotentialSubstitutes,
    WaitingSubstituteResponse,
    SubstituteImageName,
}

export enum ClassesTypes {
    AllClasses = 'All Classes',
    Groups = 'Groups',
    PrivateLessons = 'Private Lessons',
    OpenClasses = 'Open Classes',
    Webinars = 'Webinars',
    TrainingClasses = 'Training Classes',
    None = 0
}

export enum FeedbackType {
    None = 0,
    Lesson = 1,
    Course = 2
}

export enum CanContinue {
    No = 0,
    Yes = 1,
    Maybe = 2
}

export enum AbroadCourseDataType {
    Unit = 1,
    // VerseReading = 2,
    // DialogReading = 3,
    // Vocabulary = 4,
    Homework = 5,
    // GrammaticalExplanations = 6,
    // LessonSlides = 7,
    // LinkMainAbroadCourse = 8,
    // LessonMovie = 9,
    Presentation = 10,
    Text = 11,
    Video = 12,
    Audio = 13,
    Link = 14,
}

export enum WhatsApp {
    WHATSAPP_SUPPORT_URL = 'https://bit.ly/3dutFyt'
}

export const loadingConfig = {
    primaryColour: '#dd0031',
    secondaryColour: '#006ddd',
    tertiaryColour: '#dd0031',
    backdropBackgroundColour: 'rgba(0, 0, 0, 0)',
    fullScreenBackdrop: false
};

export const loadingConfigFullScreen = {
    primaryColour: '#dd0031',
    secondaryColour: '#006ddd',
    tertiaryColour: '#dd0031',
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
    fullScreenBackdrop: true
};

export enum DateFormatType {
    FlongDate = 1,
    FddDSMMDSyyyy = 2
};

export enum DateFormat {  
    FlongDate = 'LLLL dd, yyyy',
    FddDSMMDSyyyy = 'dd/MM/yyyy'
};

export enum Static {  
    HTTP_RELOAD_TIME = 3000
};
