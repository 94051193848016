import { Component } from '@angular/core';
import { TranslationService } from './services/translation/translation.service';
import { BehaviorSubject } from 'rxjs';
import { DisplayDirection } from './app.enums';

@Component({
  selector: 'et-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  languageChanged$: BehaviorSubject<DisplayDirection>;
  DisplayDirection = DisplayDirection;

  constructor(translate: TranslationService) {
    translate.init();
    this.languageChanged$ = translate.languageChanged$;
  }
}
