import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NetService } from '../net/net.service';
import * as M from '../../app.models';
import * as E from '../../app.enums';
import { map, take, tap } from 'rxjs/operators';
import { cacheTime } from '../custom-operators.helper';
//import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  privateLessonsStudents = new BehaviorSubject<{ studentName: string, studentId: string }[]>([]);
  privateReportStudents = new BehaviorSubject<{ studentName: string, studentId: string }[]>([]);
  selectedReportedPrivateLessonsId = new BehaviorSubject<number[]>([]);
  saveLessionSuccess = new BehaviorSubject<boolean>(false);
  selectedUpdatingLessions = new BehaviorSubject<M.ProductLesson[]>([]);
  lessonToReports = new BehaviorSubject<M.ProductLesson[]>([]);
  reportLessonsErrorString = new BehaviorSubject<string>('');
  private _totalLessonsToReport$ = new BehaviorSubject<number | undefined>(undefined);

  showDevSubject = new BehaviorSubject<boolean>(true);
  showDev = this.showDevSubject.asObservable();
  showAcademicSubject = new BehaviorSubject<boolean>(true);
  showAcademic = this.showAcademicSubject.asObservable();
  showContinuingSubject = new BehaviorSubject<boolean>(true);
  showContinuing = this.showContinuingSubject.asObservable();
  enableUpdateBtn = new BehaviorSubject<boolean>(true);

  get totalLessonsToReport$() {
    if (this._totalLessonsToReport$.value === undefined) {
      const date = new Date();
      this.getLessonsToReport(date.getMonth() + 1, date.getFullYear()).pipe(take(1)).subscribe();
    }
    return this._totalLessonsToReport$;
  }

  private _listExtraSalaryItems$ = new BehaviorSubject<Array<M.DevMarketingSubject> | undefined>(undefined);
  getListExtraSalaryItems$: Observable<Array<M.DevMarketingSubject>> = cacheTime(this.getListExtraSalaryItems(), E.Static.HTTP_RELOAD_TIME);

  get listExtraSalaryItems$(): BehaviorSubject<Array<M.DevMarketingSubject> | undefined> {
    if (!this._listExtraSalaryItems$.value) {
      this.fetachListExtraSalaryItems();
    }
    return this._listExtraSalaryItems$;
  }

  getListExtraSalaryItems(): Observable<Array<M.DevMarketingSubject>> {
    return this.net.getListExtraSalaryItems();
  }

  fetachListExtraSalaryItems(): void {
    this.getListExtraSalaryItems$
      .subscribe(data => this._listExtraSalaryItems$.next(data));
  }

  privateLessonsReported = new EventEmitter<void>();
  constructor(
    private net: NetService,
    //private translateService: TranslateService,
  ) { }

  clearCache() {
    this._totalLessonsToReport$.next(undefined);
    this._listExtraSalaryItems$.next(undefined);
  }

  // SUMMARY
  getPaymentSummary(month: number, year: number): Observable<M.PaymentSummary> {
    return this.net.getPaymentSummary(month, year);
  }

  updateDevAndMarketing(val: boolean) {
    this.showDevSubject.next(val);
  }
  updateAcademic(val: boolean) {
    this.showAcademicSubject.next(val);
  }
  updateContinuing(val: boolean) {
    this.showContinuingSubject.next(val);
  }

  // courses
  getLessonsToReport(month: number, year: number, courseId?: number, groupId?: number): Observable<M.LessonsToReport> {
    return this.net.getLessonsToReport(month, year, courseId, groupId).pipe(tap(val => {
      let counter = 0;
      val.products?.forEach(p => counter += p.lessons.length);
      this._totalLessonsToReport$.next(counter);
    }));
  }

  getReportedLessons(month: number, year: number, courseId?: number, groupId?: number): Observable<M.ReportedLessons> {
    return this.net.getReportedLessons(month, year, courseId, groupId);
  }

  reportLessons(reportLessons: M.ReportLessons, month: number, year: number, courseId?: number, groupId?: number) {
    return this.net.reportLessons(reportLessons, month, year, courseId, groupId);
  }

  // PRIVATE LESSONS
  getPrivateLessonsToReport(): Observable<M.PrivateLessonsToReport> {
    return this.net.getPrivateLessonsToReport();
  }

  getReportedPrivateLessons(month: number, year: number): Observable<M.PrivateLessonsToReport> {
    return this.net.getReportedPrivateLessons(month, year);
  }

  reportPrivateLessons(reportLessons: M.ReportLessons): Observable<M.PrivateLessonsReportsResult> {
    return this.net.reportPrivateLessons(reportLessons);
  }

  getPaymentsTabs(){
    return this.net.getPaymentsTabs();
  }
  
  deletePrivateLessonReports(reportIdS: number[]) {
    return this.net.deletePrivateLessonReports(reportIdS);
  }


  // DEV & MARKETING
  getDevMarketingReports(month: number, year: number): Observable<M.DevMarketingReports> {
    return this.net.getDevMarketingReport(month, year);
  }

  addExtraSalaryItemReport(reportItem: M.ExtraSalaryItemReport) {
    return this.net.addExtraSalaryItemReport(reportItem);
  }

  deleteExtraSalaryItemReports(reportIdS: number[]) {
    return this.net.deleteExtraSalaryItemReports(reportIdS);
  }

  // Academic Activity
  getAcademicActivity(month: number, year: number) {
    return this.net.getAcademicActivity(month, year);
  }

  // Continuing Students
  getContinuingStudentsByMonth(month: number, year: number) {
    return this.net.getContinuingStudentsByMonth(month, year);
  }

  getContinuingStudentsBySemetser(semesterId: number) {
    return this.net.getContinuingStudentsBySemetser(semesterId);
  }


  updatingLessions(lessions: M.ProductLesson[]) {
    return this.selectedUpdatingLessions.next(lessions);
  }

  updatingLessionToReports(lessions: M.ProductLesson[]) {
    return this.lessonToReports.next(lessions);
  }

  // GENERIC FUNCTIONS
  isNight(date: string | Date | number | undefined | null): boolean {
    if (date) {
      if (typeof date === 'number') { date = new Date(date); }
      if (typeof date !== 'string') {
        date = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
      }
      const nightHours = [
        "23:30", "23:45", "00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45",
        "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30"
      ];
      return nightHours.includes(date);
    }
    else return false;
  }

  returnReportLessonsObject(data: M.ProductLesson): M.ReportLessons {
    const temp: M.ReportLessons = {
      teacherId: 18143,
      lessons: [
        {
          orderItemId: data.orderItemId,
          date: data.date,
          duration: data.duration,
          notes: data.notes,
          unitNumber: data.unitNumber || -1,
          slideNumber: data.slideNumber || -1,
          hoursReportConfirmType: data.hoursReportConfirmType,
          cancelReason: data.cancelReason,
          isNight: this.isNight(data.date)
        }
      ] as M.ProductLesson[]
    };
    temp.lessons = temp.lessons.map(l => {
      if (typeof l.date === 'string') {
        const [hours, mintues] = l.date.split(":");
        l.date = new Date();
        l.date.setHours(parseInt(hours), parseInt(mintues), 0, 0);
        l.date = l.date.getTime() - l.date.getTimezoneOffset() * 60 * 1000
      }
      return l;
    });
    return temp;
  }

  returnCalculatedTimeString(time: string, length: string) {
    let finalH, finalM;
    const [timeH, timeM] = time.split(":");
    const [lengthH, lengthM] = length.split(":");
    finalH = parseInt(timeH) + parseInt(lengthH);
    finalM = parseInt(timeM) + parseInt(lengthM);
    if (finalM > 45) { finalM -= 60; finalH++; }
    if (finalH > 24) { finalH -= 24; }
    return `${finalH.toString().padStart(2, "0")}:${finalM.toString().padStart(2, "0")}`;
  }

  returnReportErrorString(error: E.ReportPrivateLessonError): string {
    switch (error) {
      case E.ReportPrivateLessonError.ReportTimeExists:
      //return this.translateService.instant("Strings.AnotherLessonsReportedOnThatTime");
      default:
        return '';
    }
  }

}
