import { Injectable } from '@angular/core';
import * as E from '../../app.enums';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  constructor() { }

  save(key: E.StorageKey, value: any): void {
    localStorage.setItem(key.toString(), JSON.stringify(value));
  }

  remove(key: E.StorageKey){
    localStorage.removeItem(key);
  }

  load<T>(key: E.StorageKey): T | undefined {
    const value = localStorage.getItem(key.toString());
    if (!value) {
      return undefined;
    }

    return JSON.parse(value);
  }
  getValue(key: E.StorageKey): string {
    const value = localStorage.getItem(key.toString());
    if (!value) {
      return "";
    }

    return value.replace(/\"/g, '');
  }
  static get<T>(key:E.StorageKey):T|undefined{
    const value = localStorage.getItem(key.toString());
    if (!value) {
      return undefined;
    }

    return JSON.parse(value);
  }
}
