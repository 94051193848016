import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { LoginService } from '../services/login/login.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // No VPN headers
    request = request.clone({
      setHeaders: {
        "CF-Access-Client-Id": "57f1ff0f2e9b03fe2ee089531705beaf.access",
        "CF-Access-Client-Secret": "34ed57f8e7c3415c811213ea81c0b8285eb63919260c4634582faf202618496c",
      }
    });
    // ------

    
    let token = this.loginService.token;
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse && err.status === 401 && err.url?.includes(environment.gatewayUrl)) {
        this.loginService.logOut();
      }
      return throwError(err);
    }));
  }
}
