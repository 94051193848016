import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NetService } from '../net/net.service';
import { Notification } from '../../app.models';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private _notifications$ = new BehaviorSubject<Notification[] | undefined>(undefined);

  get notifications$() {
    if (this._notifications$.value === undefined) {
      this.initialNotifications();
    }
    return this._notifications$;
  }

  constructor(
    private net: NetService
  ) { }

  getNotifications() {
    return this.net.getNotifications()
    .pipe(tap(notifications => this._notifications$.next(notifications)));
  }

  clearCache(){
    this._notifications$.next(undefined);
  }

  private initialNotifications() {
    this.getNotifications().pipe(take(1)).subscribe(res => this._notifications$.next(res));
  }
}
