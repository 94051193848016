import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './guards/auth/auth.guard';
import {NotFoundView} from './views/not-found/not-found.view';

const routes: Routes = [
  { 
    path: 'login', 
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) 
  },
  { 
    path: 'forgot-password', 
    loadChildren: () => import('./modules/login/forgot-password.module').then(m => m.ForgotPasswordModule) 
  },
  { 
    path: 'reset-password', 
    loadChildren: () => import('./modules/login/reset-password.module').then(m => m.ResetPasswordModule) 
  },
  { 
    path: 'reset-password-from-crm', 
    loadChildren: () => import('./modules/login/reset-password-from-crm.module').then(m => m.ResetPasswordFromCrmModule) 
  },
  {
    path: '',
    loadChildren: () => import('./modules/master/master.module').then(m => m.MasterModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: '**',
    component: NotFoundView
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled',enableTracing:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
