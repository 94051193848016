import { Injectable } from '@angular/core';
import { InfoForReorderViewRequest, ListStudentFeedbacksFilters, UpdateAcademicStudentsForGrading } from 'src/app/app.models';
import { NetService } from '../net/net.service';
import { BehaviorSubject, Observable } from 'rxjs';
import * as M from '../../app.models';
import * as E from '../../app.enums';
import { cacheTime } from '../custom-operators.helper';
import { publishReplay, refCount, take, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StudentService {

  private _academicStudentsForGrading$ = new BehaviorSubject<M.AcademicStudentsForGrading | undefined>(undefined);
  private _studentApplications$ = new BehaviorSubject<M.StudentApplications | undefined>(undefined);  
  myStudentTab = new BehaviorSubject<boolean>(false);

  constructor(
    private net: NetService
  ) { }

  getStudentDetails(studentId: number) {
    return this.net.getStudentDetails(studentId);
  }

  getTeacherCourseStudents(courseId: number) {
    return this.net.getTeacherCourseStudents(courseId);
  }

  getListStudentFeedbacks(requestFilters: ListStudentFeedbacksFilters) {
    return this.net.getListStudentFeedbacks(requestFilters);
  }

  getListLevelTests(studentId: number) {
    return this.net.getListLevelTests(studentId);
  }

  getListAttendancesAndRecordings(studentId: number, fromDate: number, toDate: number) {
    return this.net.getListAttendancesAndRecordings(studentId, fromDate, toDate);
  }

  getListStudentAttendanceSummaries(studentId: number) {
    return this.net.getListStudentAttendanceSummaries(studentId);
  }

  getListCoursesByStudent(studentId: number) {
    return this.net.getListCoursesByStudent(studentId);
  }

  getListCourseStudents(courseId: number) {
    return this.net.getListCourseStudents(courseId);
  }


  listInfoForReorder(studentId: number, courseId?: number) {
    return this.net.listInfoForReorder(studentId, courseId);
  }

  addUpdateInfoForReorder(infoForReorder: InfoForReorderViewRequest) {
    return this.net.addUpdateInfoForReorder(infoForReorder);
  }

  getStudentApplications() {
    return this.net.getStudentApplications().pipe(
      publishReplay(1, E.Static.HTTP_RELOAD_TIME),
      refCount(),
      take(1));
  }

  private initialStudentApplications() {
    this.getStudentApplications().pipe(
      publishReplay(1, E.Static.HTTP_RELOAD_TIME),
      refCount(),
      take(1)).subscribe(res => this._studentApplications$.next(res));
  }

  get getStudentApplications$() {
    if (this._studentApplications$.value === undefined) {
      this.initialStudentApplications();
    }
    return this._studentApplications$;
  }

  get getAcademicStudentsForGrading$() {
    if (this._academicStudentsForGrading$.value === undefined) {
      this.initialAcademicStudentsForGrading();
    }
    return this._academicStudentsForGrading$;
  }

  clearCache() {
    this._academicStudentsForGrading$.next(undefined);
    this._studentApplications$.next(undefined);

  }

  getAcademicStudentsForGrading() {
    return this.net.getAcademicStudentsForGrading().pipe(
      publishReplay(1, E.Static.HTTP_RELOAD_TIME),
      refCount(),
      take(1));
  }

  private initialAcademicStudentsForGrading() {
    this.getAcademicStudentsForGrading().pipe(
      publishReplay(1, E.Static.HTTP_RELOAD_TIME),
      refCount(),
      take(1)).subscribe(res => this._academicStudentsForGrading$.next(res));
  }

  getQuestionaire(studentId: number) {
    return this.net.getQuestionaire(studentId);
  }

  updatePedagogicalNotes(notes: string, orderId: number) {
    return this.net.updatePedagogicalNotes(notes, orderId);
  }

  updateAcademicStudentsForGrading(updateAcademicGrading: UpdateAcademicStudentsForGrading) {
    return this.net.updateAcademicStudentsForGrading(updateAcademicGrading);
  }

  getListPendingInfoForReorder() {
    return this.net.getListPendingInfoForReorder();
  }

  getTeacherStudentDstChanges(studentId: number) {
    return this.net.getTeacherStudentDstChanges(studentId);
  }

  getTeacherStudents(){
    return this.net.getAllTeacherStudents();
  }

  listCompletedTypeFormQuestionnaires(studentId: number) {
    return this.net.listCompletedTypeFormQuestionnaires(studentId);
  }
}
