// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    gatewayUrl: 'https://stg-sirius.eteachergroup.com/tms-gateway/api',
    //   gatewayUrl: 'https://localhost:44350/api',
    //  errorLoggerApiUrl: 'https://tms@localhost:44350/api/Settings/logClientError/1',
    errorLoggerApiUrl: 'https://tms@stg-sirius.eteachergroup.com/tms-gateway/api/Settings/logClientError/1',

  /*
  Log levels:
    TRACE = 0,
    DEBUG = 1,
    INFO = 2,
    LOG = 3,
    WARN = 4,
    ERROR = 5,
    FATAL = 6,
    OFF = 7
    */
  logLevel: 0
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
