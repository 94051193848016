import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CourseMaterial, CourseMaterialsForPublishing, CourseMessage, UnitItemsForPublishing } from 'src/app/app.models';
import { NetService } from '../net/net.service';
import * as M from '../../app.models';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClassesService {

  constructor(
    private net: NetService
  ) { }

  private _classes$ = new BehaviorSubject<M.Classes | undefined>(undefined);
  private _group$ = new BehaviorSubject<M.Group | undefined>(undefined);
  private _lession$ = new BehaviorSubject<M.Lesson | undefined>(undefined);

  get getClasses$() {
    if (this._classes$.value === undefined) {
      this.initialClasses();
    }
    return this._classes$;
  }

  initialClasses() {
    this.getClasses().pipe(take(1)).subscribe(res => {
      this.addClass(res);
    });
  }

  addClass(res: M.Classes) {
    const view: M.Classes = {
      groups: [],
      openClasses: [],
      webinars: [],
      privateLessons: [],
      trainingClasses: []
    };
    if (res) {
      res.groups.forEach(data => {
        if (!view.groups.find(r => r?.semesterName === data?.semesterName && r?.courseName === data?.courseName && r?.groupNumber === data?.groupNumber)) {
          view.groups.push(data);
        } else if (data.isForContentPublish) {
          view.groups.map(r => {
            if (r?.semesterName === data?.semesterName && r?.courseName === data?.courseName && r?.groupNumber === data?.groupNumber) {
              r.isForContentPublish = data.isForContentPublish;
            }
            return r;
          })
        }
      });
      res.openClasses.forEach(data => {
        if (!view.openClasses.find(r => r?.courseName === data?.courseName && r?.groupNumber === data?.groupNumber && r?.campusName === data?.campusName)) {
          view.openClasses.push(data);
        } else if (data.isForContentPublish) {
          view.openClasses.map(r => {
            if (r?.courseName === data?.courseName && r?.groupNumber === data?.groupNumber && r?.campusName === data?.campusName) {
              r.isForContentPublish = data.isForContentPublish;
            }
            return r;
          })
        }
      });
      res.webinars.forEach(data => {
        // if(!view.webinars.find(r => r?.courseName === data?.courseName && r?.campusName === data?.campusName)){
        view.webinars.push(data);
        // }
      });
      res.privateLessons.forEach(data => {
        if (!view.privateLessons.find(r => r?.studentId === data?.studentId && r?.studentName === data?.studentName && r?.courseId === data?.courseId && r?.orderId === data?.orderId && r?.orderItemId === data?.orderItemId)) {
          view.privateLessons.push(data);
        }else{
          view.privateLessons.map(r => {
            if (r?.studentId === data?.studentId && r?.studentName === data?.studentName && r?.courseId === data?.courseId && r?.orderId === data?.orderId && r?.orderItemId === data?.orderItemId) {
              r.isForContentPublish = data.isForContentPublish;
            }
            return r;
          })
        }
      });
      res.trainingClasses.forEach(data => {
        // if(!view.trainingClasses.find(r => r?.courseName === data?.courseName &&  r?.campusName === data?.campusName)){
        view.trainingClasses.push(data);
        // }
      });
    }
    this._classes$.next(view)
    return view;
  }

  getClasses() {
    return this.net.getClasses();
  }

  resetClasses() {
    return this.net.getClasses().pipe(map(res => this.addClass(res)));
  }

  setGroup(group: M.Group | undefined) {
    this._group$.next(group)
  }

  get getGroup$() {
    return this._group$;
  }

  setLession(ls: M.Lesson | undefined) {
    this._lession$.next(ls)
  }

  get getLession$() {
    return this._lession$;
  }

  clearCache() {
    this._group$.next(undefined);
    this._classes$.next(undefined);
    this._lession$.next(undefined)
  }

  getTecaherSemesters(groupId: number, year: number) {
    return this.net.getTeacherSemestersData(groupId, year);
  }

  getTeacherOpenClasses(groupId: number) {
    return this.net.getTeacherOpenClasses(groupId);
  }

  getCourseListRecordings(courseId: number) {
    return this.net.getCourseListRecordings(courseId);
  }

  getCourseListMessages(courseId: number) {
    return this.net.getCourseListMessages(courseId);
  }

  deleteCourseMessage(messageId: number) {
    return this.net.deleteCourseMessage(messageId);
  }

  getListMaterials(courseId: number) {
    return this.net.getListMaterials(courseId);
  }

  getListAbroadCourseMaterials(courseId: number, abroadCourseId:number) {
    return this.net.getListAbroadCourseMaterials(courseId, abroadCourseId);
  }

  getListUnits(courseId: number) {
    return this.net.getListUnits(courseId);
  }

  deleteCourseMaterial(courseDataId: number) {
    return this.net.deleteCourseMaterial(courseDataId);
  }

  addUpdateCourseMaterial(courseId: number, courseMaterial: CourseMaterial, tempFileId?: number) {
    return this.net.addUpdateCourseMaterial(courseId, courseMaterial, tempFileId);
  }

  addUpdateCourseMessage(courseId: number, message: CourseMessage) {
    return this.net.addUpdateCourseMessage(courseId, message);
  }

  updateCourseMaterials(data: CourseMaterialsForPublishing) {
    return this.net.updateCourseMaterials(data);
  }

  updateAbroadCourseMaterials(data: CourseMaterialsForPublishing) {
    return this.net.updateAbroadCourseMaterials(data);
  }

  updateUnitItems(data: UnitItemsForPublishing) {
    return this.net.updateUnitItems(data);
  }

  getListCourseReportsSummary(courseId: number) {
    return this.net.getListCourseReportsSummary(courseId);
  }

}
