import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import * as E from '../../app.enums';
import { LoginService } from '../login/login.service';
import { LoggerService } from '../logger/logger.service';
import { PersistenceService } from '../persistence/persistence.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  languageChanged$: BehaviorSubject<E.DisplayDirection> = new BehaviorSubject<E.DisplayDirection>(E.DisplayDirection.LeftToRight);

  constructor(
    private translate: TranslateService,
    private logger: LoggerService,
    private persistence: PersistenceService,
    login: LoginService) {
    translate.addLangs(['en', 'he']);
    login.loggedInTeacher$.subscribe(teacher => {
      // TODO: Uncomment!
      if (teacher) {
        this.setLanguage(teacher.languageCode);
      }
    });
  }

  init() {

    const languageName = this.persistence.load<string>(E.StorageKey.Language) || 'en';
    this.translate.setDefaultLang('en');
    this.setLanguage(languageName);
  }

  setLanguage(language: string): void {
    language = language ? language.toLocaleLowerCase() : 'en';

    this.logger.debug(`Setting UI language to ${language}`);
    this.persistence.save(E.StorageKey.Language, language);

    this.translate.setDefaultLang(language);
    this.translate.use(language).subscribe(_ => {
    // let displayDirection = E.DisplayDirection.LeftToRight;
    // // if (language === 'he') {
    //   displayDirection = E.DisplayDirection.RightToLeft;
    // // }

    // this.languageChanged$.next(displayDirection);
    });
  }
}
