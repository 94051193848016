import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NetService } from '../net/net.service';
import * as M from '../../app.models';
import * as E from '../../app.enums';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  private _teacherCampuses$ = new BehaviorSubject<M.TeacherCampus[] | undefined>(undefined);
  private _teacherScheduleDstDates$ = new BehaviorSubject<M.TeacherScheduleDstDates[] | undefined>(undefined);

  get teacherCampuses$() {
    if (this._teacherCampuses$?.value === undefined) {
      this.net.getTeacherCampuses()
        .pipe(tap(campuses => this._teacherCampuses$?.next(campuses)), take(1)).subscribe();
    }
    return this._teacherCampuses$;
  }


  private _teacherCourses$ = new BehaviorSubject<M.TeacherCourse[] | undefined>(undefined);

  get teacherCourses$() {
    if (this._teacherCourses$?.value === undefined) {
      this.net.getTeacherCourses()
        .pipe(tap(courses => this._teacherCourses$?.next(courses)), take(1)).subscribe();
    }
    return this._teacherCourses$;
  }


  private _teacherSemesters$ = new BehaviorSubject<M.Semester[] | undefined>(undefined);

  get teacherSemesters$() {
    if (this._teacherSemesters$?.value === undefined) {
      this.net.getTeacherSemesters()
        .pipe(tap(semesters => this._teacherSemesters$?.next(semesters)), take(1)).subscribe();
    }
    return this._teacherSemesters$;
  }
  constructor(
    private net: NetService
  ) { }  

  clearCache() {
    this._teacherCampuses$.next(undefined);
    this._teacherCourses$.next(undefined);
    this._teacherSemesters$.next(undefined);
    this._teacherScheduleDstDates$.next(undefined);
  }

  getAbroadCourseAssignments(abroadCourseId: number){
    return this.net.getAbroadCourseAssignments(abroadCourseId)
  }

  getGroupTeachers(groupId: number){
    return this.net.getGroupTeachers(groupId)
  }

  teacherScheduleDstDates(){
    return this.net.teacherScheduleDstDates()
  }
  
  get teacherScheduleDstDates$() {
    if (this._teacherScheduleDstDates$?.value === undefined) {
      this.net.teacherScheduleDstDates()
        .pipe(tap(data => this._teacherScheduleDstDates$?.next(data)), take(1)).subscribe();
    }
    return this._teacherScheduleDstDates$;
  }
}
