import { Injectable } from '@angular/core';
import { NetService } from '../net/net.service';
import * as M from '../../app.models';
import { Observable, BehaviorSubject } from 'rxjs';
import {take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})export class CourseService {  
  private _listTeacherOnAirLessons$ = new BehaviorSubject<M.TeacherOnAirLessons[] | undefined>(undefined);
  constructor(private net: NetService) { }

  
  get listTeacherOnAirLessons$() {
    if (this._listTeacherOnAirLessons$.value === undefined) {
      this.initialListTeacherOnAirLessons();
    }
    return this._listTeacherOnAirLessons$;
  }

  clearCache() {
    this._listTeacherOnAirLessons$.next(undefined);
  }

  private initialListTeacherOnAirLessons() {
    this.getListTeacherOnAirLessons().pipe(take(1)).subscribe(res => this._listTeacherOnAirLessons$.next(res));
  }

  getListTeacherOnAirLessons(): Observable<M.TeacherOnAirLessons[]> {
    return this.net.getListTeacherOnAirLessons()
    .pipe(tap(res => this._listTeacherOnAirLessons$.next(res)));
  }

  getEnterLessonUrl(id:number): Observable<any> {
    return this.net.getEnterLessonUrl(id);
  }

  getListNationalGeographicCourses():  Observable<M.NationalGeographicCourse[]> {
    return this.net.getListNationalGeographicCourses();
  }
}
